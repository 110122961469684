import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService } from './auth.service';

const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  { path: 'language', loadChildren: './language/language.module#LanguagePageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule', canActivate: [AuthService], data: {page:'login'} },
  { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'password-reset', loadChildren: './password-reset/password-reset.module#PasswordResetPageModule' },
  { path: 'app', loadChildren: './app/app.module#AppPageModule', canActivate: [AuthService], data: {page:'app'} },
  { path: 'create-profile', loadChildren: './create-profile/create-profile.module#CreateProfilePageModule' },
//   { path: 'change-email', loadChildren: './change-email/change-email.module#ChangeEmailPageModule' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
