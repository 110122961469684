import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataService } from './data.service';
import { Storage } from '@ionic/storage';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public ds: DataService,
    public storage: Storage,
    public push: Push
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if(this.platform.is('android')) this.statusBar.styleLightContent();
      else this.statusBar.styleDefault();
      if(this.ds.load('language')) {
        if(this.ds.load('direction') == 'rtl') document.documentElement.dir = 'rtl';
        this.ds.get('languages/labels/'+this.ds.load('language')).then((data:any) => {
          this.ds.labels = data.labels;
          this.ds.legal = data.legal;
          this.ds.save('labels',data.labels);
        })
      } else {
        localStorage.clear();
      }
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      this.storage.ready().then(() => {
        this.storage.get(this.ds.app+'content').then((val) => {
          if(val) {
            this.ds.content = JSON.parse(val).sdata;
          }
        }).catch((err)=> {
          console.error('content',err);
        })
        this.storage.get(this.ds.app+'indexes').then((val) => {
          if(val) {
            this.ds.indexes = JSON.parse(val).sdata;
          }
        }).catch((err)=> {
          console.error('indexes',err);
        })
        this.storage.get(this.ds.app+'verses').then((val) => {
          if(val) {
            this.ds.verses = JSON.parse(val).sdata;
          }
        }).catch((err)=> {
          console.error('verses',err);
        })
      })
      if(this.platform.is('cordova')) {
        if(this.platform.is('android')) {
          this.push.createChannel({
            id: "appDefault",
            description: "Default App Channel",
            importance: 4
          });
        }
        const pushOpts : PushOptions = {
          android: {
            icon: 'push_icon',
            iconColor: '#e21836',
            sound: true,
            vibrate: true,
            clearBadge: true
          },
          ios: {
            alert: true,
            badge: false,
            sound: true,
            clearBadge: true
          }
        };
        const pushObject: PushObject = this.push.init(pushOpts);
        pushObject.on('notification').subscribe((notification: any) => {
          //console.log('Received a notification', notification)
        });
        pushObject.on('registration').subscribe((registration: any) => {
          this.ds.setDevice({push_token:registration.registrationId});
        });
      }
    });
  }
}
