import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  app: string = 'sc';
  httpOpts: any = {
    headers: new HttpHeaders()
  }
  ep: string = 'https://smallcircle.io/_endpoints/app/';
  user:any;
  token:any;
  connectionID: any;
  makerID: any;
  language:any = this.load('language');
  labels: any = this.load('labels');
  progress: any = {};
  content:any = null;
  books:any = [];
  indexes:any = null;
  verses: any = null;
  chatCount: number = 0;
  countries: any;
  languages: any;
  //lastComplete: any;
  notes: any = {};
  favorites: any = {};
  leftOff: any = {};
  device: any = {};
  legal: any = {};
  constructor(public http: HttpClient, public alertCtrl: AlertController, public loadingCtrl: LoadingController, public platform: Platform, public perms: AndroidPermissions) {}
  setBooks() {
    for(let i=0;i<this.content.length;i++) {
      this.books[i].title = this.content[i].title;
      this.books[i].image = this.content[i].image;
      this.books[i].total = 0;
      this.books[i].complete = 0;
      this.books[i].percent = 0;
      this.getProgress(this.content[i],i);
    }
    this.post('user/record_progress',{book_progress:this.books,left_off:this.leftOff});
  }
  getProgress(section:any,idx:any = null) {
    if(section.subpages) {
      let progressStr = JSON.stringify(this.progress);
      let progress = JSON.parse(progressStr)
      for(let i=0;i<section.subpages.length;i++) {
        this.books[idx].total++;
        if(progress[section.subpages[i].id]) this.books[idx].complete++;
        this.getProgress(section.subpages[i],idx);
        let percent = this.books[idx].complete/this.books[idx].total;
        this.books[idx].percent = Math.floor(percent*100);
      }
    }
  }
  checkPerms(perms:any = []) {
    return new Promise((resolve) => {
      if(this.platform.is('android')) {
        let granted = true;
        for(let i=0;i<perms.length;i++) {
          this.perms.checkPermission(this.perms.PERMISSION[perms[i]]).then((data:any) => {
            if(data.hasPermission) {
              if(i+1 == perms.length) resolve({granted:true});
            } else {
              this.perms.requestPermission(this.perms.PERMISSION[perms[i]]).then(() => {
                if(i+1 == perms.length) resolve({granted:true});
              }).catch((err) => {
                console.error(err)
                granted = false;
              })
            }
          }).catch((err) => {
            console.error(err)
            granted = false;
          })
          if(!granted) {
            this.dataError({
              message: 'Invalid Permissions'
            })
            break;
          }
        }
      } else {
        resolve({granted:true});
      }
    })
  }
  save(key:string,data:any) {
    localStorage.setItem(this.app+key,JSON.stringify({sdata:data}));
  }
  load(key:string) {
    if(localStorage.getItem(this.app+key)) {
      var stringifiedData = localStorage.getItem(this.app+key);
      return JSON.parse(stringifiedData).sdata;
    } else {
      return null;
    }
  }
  setToken(token:string = null) {
    if(token) {
      this.httpOpts = {
        headers: new HttpHeaders({
          'X-Auth-Token':token
        })
      }
    } else {
      console.log(1);
      this.httpOpts = {
        headers: new HttpHeaders()
      }
      console.log(this,this.httpOpts);
    }
  }
  setDevice(params:any) {
    if(this.platform.is('cordova')) {
      for(let key in params) this.device[key] = params[key];
      if(this.device.uuid) {
        this.post('user/register_device',this.device);
      }
    }
  }
  dataError(res) {
    let message = '';
    for(let key in res) {
      message += res[key]+'. ';
    }
    this.alertCtrl.create({
      header: 'Error',
      message: message,
      buttons: ['CLOSE']
    }).then(alert => {
      this.loadingCtrl.dismiss();
      alert.present();
    })
  }
  serverError() {
    this.alertCtrl.create({
      header: 'Server Error',
      message: 'Unable to Connect to Server. Please Check Your Internet Connection',
      buttons: ['CLOSE']
    }).then(alert => {
      this.loadingCtrl.dismiss();
      alert.present();
    })
  }
  get(command:string,data:any=null) {
    let args = '';
    if(data) {
      args = '?';
      for(var key in data) {
        args += key+'='+data[key]+'&';
      }
    }
    return new Promise((resolve) => {
      this.http.get(this.ep+command+args, this.httpOpts).toPromise().then((response:any) => {
        console.log(command+' data: ', response);
        if(response) {
          if(response.error) {
            this.dataError(response.error);
          } else {
            resolve(response);
          }
        }
      }).catch((error) => {
        console.error(error);
        this.serverError();
      });
    });
  }
  post(command:string,data:any,ignoreErr:boolean=false) {
    return new Promise((resolve) => {
      this.http.post(this.ep+command,JSON.stringify(data), this.httpOpts).toPromise().then((response:any) => {
        console.log(command+' data: ', response);
        if(response) {
          if(response.error && !ignoreErr) {
            this.dataError(response.error);
          } else {
            resolve(response);
          }
        }
      }).catch((error) => {
        console.error(error);
        this.serverError();
      });
    });
  }
  getDate(country:string) {
    return new Promise((resolve) => {
      this.http.get(this.ep+'languages/getDateTime/'+country).toPromise().then((response:any) => {
        resolve(response);
      });
    });
  }
}
