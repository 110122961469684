import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  constructor(public ds: DataService, public router: Router) { }
  canActivate(route:ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if(route.data.page == 'login') {
      if(this.ds.token && this.ds.user) {
        return false;
      } else if(!this.ds.language) {
        this.router.navigateByUrl('/language');
        return false;
      } else {
        return true;
      }
    } else {
      if(this.ds.token && this.ds.user) {
        return true;
      } else {
        this.router.navigateByUrl('/login');
        return false;
      }
    }
  }
}